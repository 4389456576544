import React, {FC, useState, useCallback, ReactNode} from "react";
import clsx from "clsx";
import {Steps, Popover} from "antd";
import useStyle from "./css";
import flexStyle from "../../common/style/flex";
import {StepList} from "../../api";
import JtjjPng from "../../assets/jtjj.png"
import {goPAGE} from "../../common/utils";
import TitleBar from "../../components/TitleBar";
import FazhanLicheng from "../../assets/fazhanlicheng.png";

const {Step} = Steps;

const customDot = (dot: any, {status, index, title, description}: {status: string, index: number, title: ReactNode, description: ReactNode}) => (
    <Popover
        content={
            <>
                <div>{title}</div>
                <div>{description}</div>
            </>
        }
    >
        {dot}
    </Popover>
);
const Intros: FC = () => {
    const {tagContentTitle, title, introduction} = useStyle(goPAGE());
    const {flex, flexCrossCenter, flexMainCenter, flexCrossStart} = flexStyle();
    const [current, setCurrent] = useState<number>(StepList.length);
    const onChangeCurrent = useCallback((value) => {
        setCurrent(value)
    }, [setCurrent])
    return (
        <div>
            <div className={clsx(flex, flexCrossStart, introduction)} style={{margin: "80px 0 0 0 "}}>
                <div className={clsx()} style={{padding: "0 40px 0 0", boxSizing: "border-box"}}>
                    <p className={clsx(title)}>集团简介</p>
                    <TitleBar />
                    <p className={clsx(tagContentTitle)}>
                        恒祥控股集团创建于1995年，从浙江省玉环市做汽车标准件开始，秉持刻苦、博学和热爱的价值观，至今
                        发展涵盖汽车零部件、智能电子、投资和商业贸易等领域；现集团旗下有9家公司，是浙江省一家优秀的民营企
                        业集团。
                    </p>
                    <p className={clsx(tagContentTitle)}>在汽车零部件领域里，深耕汽车零部件行业逾二十载，在汽车转向系统、发动机系统产品和技术具有核心竞
                        争力。时刻响应国家政策，利用创新赋能推动智能智造并转型升级，已经全面迈入创新驱动高质量发展的新阶段。
                    </p>
                    {
                        goPAGE()
                            ? (
                                <div style={{width: "100%"}}>
                                    <img src={JtjjPng} alt="" />
                                </div>
                            )
                            : null
                    }
                </div>
                {
                    goPAGE() === false
                        ? (
                            <div style={{width: "100%"}}>
                                <img src={JtjjPng} alt="" />
                            </div>
                        )
                        : null
                }

            </div>

            <div className={clsx(title)} style={{textAlign: "center", margin: "100px 0 0 0"}}>发展历程</div>
            <div className={clsx(flex, flexMainCenter)}>
                <TitleBar />
            </div>
            <div style={{margin: "0 0 80px 0"}}>
                {/* <Steps current={current} onChange={onChangeCurrent} progressDot={customDot}>
                    {
                        StepList.map(({title, description}) => <Step title={title} description={description} key={title} />)
                    }
                </Steps> */}
                <img src={FazhanLicheng} width="100%" alt="" />
            </div>

        </div>
    )
};

export default Intros;
