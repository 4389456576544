import React, {FC, Suspense} from "react";
import RootRouterMap from "./routers";

const App: FC = () => (
    <Suspense fallback={<div>loading...</div>}>
        <RootRouterMap />
    </Suspense>
);

export default App;
