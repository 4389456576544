import React, {FC} from "react";
import ElectronicPng from "../../assets/electronic_content.png";
import ElectronicTabPng from "../../assets/zhuyaochanpin.png";
import clsx from "clsx";
import flexStyle from "../../common/style/flex";

const Electronic: FC = () => {
    const {flex, flexMainCenter} = flexStyle();
    return (
        <div>
            {/* <div style={{fontSize: "36px", fontWeight: "bold", margin: "40px 0 0 0 ", textAlign: "center"}}>主要产品</div>
            <div className={clsx(flex, flexMainCenter)}>
                <div style={{width: "70px", height: "10px", background: "rgb(23, 83, 148)", margin: "0 0 40px 0"}}></div>
            </div> */}
            {/* <img src={ElectronicPng} alt="" style={{width: "100%", margin: "0 0 20px 0"}} /> */}
            <img src={ElectronicTabPng} alt="" style={{width: "100%"}} />
        </div>
    )
};

export default Electronic;
