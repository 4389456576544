import {createUseStyles} from "react-jss";


const IndustrialDistributionStyle = createUseStyles({
    root: {
        width: "1200px",
        boxSizing: "border-box",
        padding: "20px 20px",
    },
    title: {
        fontSize: "36px",
        margin: "0",
        fontWeight: "bold"
    },
    honorTabActive: {
        color: "#1890ff",
        borderBottom: "1px solid #1890ff"
    },
    pStyle: {
        // textIndent: "2em",
        padding: "20px 40px 0 0",
        boxSizing: "border-box",
        fontSize: "16px"
    },
    introduction: {
        "& img": {
            width: (props: boolean) => props ? "100%" : "100%",
            margin: (props: boolean) => props ? "20px 0 20px 0" : "0 0 0 20px"
        }
    },
    carBtn: {
        width: "350px",
        height: (props: boolean) => props ? "50px" : "100px",
        background: "rgba(23, 83, 148, 1)",
        color: "white",
        fontSize: (props: boolean) => props ? "14px" : "22px",
        transition: "width 0.2s, height 0.2s",
        "&:hover": {
            width: "370px",
            height: "120px"
        }
    }
});

export default IndustrialDistributionStyle;
