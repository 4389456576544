import React, {FC} from "react";
import clsx from "clsx";
import useStyle from "./css";
import flexStyle from "../../common/style/flex";
interface TabProps {
    list: {
        id: string;
        name: string;
    }[];
    tabId: string;
    setTabId: (value: string) => void;

}
const Tab: FC<TabProps> = (props) => {
    const {list, tabId, setTabId} = props;
    const {tagList, tag, tagActive} = useStyle();
    const {flex, flexCrossCenter, flexMainCenter, cursor, flexItem} = flexStyle();
    return (
        <div className={clsx(tagList, flex, flexCrossCenter)}>
            {
                list.map(({name, id}) => <div key={id}
                    className={clsx(tag, flexItem, flex, cursor, flexCrossCenter, flexMainCenter, tabId === id ? tagActive : null)}
                    onClick={() => setTabId(id)}
                >{name}</div>)
            }
        </div>
    )
};

export default Tab;
