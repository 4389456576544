import {createUseStyles} from "react-jss";
import Qiche from "../../assets/qiche2.png";
import Qiche2 from "../../assets/qiche3.png";
import Dianzi from "../../assets/dianzi2.png";
import Dianzi2 from "../../assets/dianzi3.png";
import Maoyi from "../../assets/maoyi2.png";
import Maoyi2 from "../../assets/maoyi3.png";
const useStyleV1 = createUseStyles({
    root1: {
        height: "100%",
        background: `url(${Qiche2})`,
        backgroundSize: "100% 100%",
        "&:hover": {
            background: `url(${Qiche})`,
            backgroundSize: "100% 100%",
        }
    },
    dianziStyle: {
        height: "100%",
        background: `url(${Dianzi2})`,
        backgroundSize: "100% 100%",
        "&:hover": {
            background: `url(${Dianzi})`,
            backgroundSize: "100% 100%",
        }
    },
    maoyiStyle: {
        height: "100%",
        background: `url(${Maoyi2})`,
        backgroundSize: "100% 100%",
        "&:hover": {
            background: `url(${Maoyi})`,
            backgroundSize: "100% 100%",
        }
    }
});

export default useStyleV1;
