export const uuid = () => "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) ?? 0;
    const v = c === "x" ? r : (r !== 0 && 0x3) ?? 0x8;
    return v.toString(16);
});

export const goPAGE = () => {
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
        /*window.location.href="你的手机版地址";*/
        return true;
    }
    else {
        /*window.location.href="你的电脑版地址";    */
        return false;
    }
}
