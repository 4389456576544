import React, {FC, useEffect, useState, useMemo} from "react";
import {useParams} from "react-router-dom";
import clsx from "clsx";
import flexStyle from "../../common/style/flex";
import Carousel from "../../components/Carousel";
import Tab from "../../components/Tab";
import Layout1 from "../../assets/baner3.png";
import ImageList from "./ImgList";
import useStyle from "./css";
import Liaoyang from "../../assets/yuangong.png";
import Shengri from "../../assets/shengri.png";
import zhishu from "../../assets/zhishu.png";
import juanzeng from "../../assets/juanzeng.png";

const list = [
    {
        name: "员工关怀",
        id: "1"
    },
    {
        name: "社会责任",
        id: "2"
    }
]

const PublicBenefit: FC = () => {
    const {flex, flexMainCenter, flexCrossCenter} = flexStyle();
    const {panelRoot} = useStyle();
    const {id} = useParams() as {id: string};

    const [tagId, setTagId] = useState<string>("1");
    const [imageList] = useState<string[]>([Layout1]);
    const [imgList, setImgList] = useState<{name: string, path: string}[][]>([]);


    useEffect(() => {
        if (id !== undefined) {
            setTagId(id);
        }
    }, [id, setTagId]);

    useEffect(() => {
        if (tagId === "1") {
            setImgList([[{name: "定期组织员工疗养", path: Liaoyang}, {path: Shengri, name: "每月集体生日会"}], [{path: zhishu, name: "环保公益植树"}, {name: "社会公益基金捐赠", path: juanzeng}]])
        } else {
            setImgList([[{path: zhishu, name: "环保公益植树"}, {name: "社会公益基金捐赠", path: juanzeng}], [{name: "定期组织员工疗养", path: Liaoyang}, {path: Shengri, name: "每月集体生日会"}]])
        }
    }, [tagId, setImgList])

    const Content = useMemo(() => {
        if (tagId === "1") {
            return (
                <p style={{textIndent: "2em", fontSize: 16, margin: "40px 0"}}>
                    努力构建企业和员工协同发展的良好局面，关心关爱员工，想员工之所想，急员工之所急。坚定不移提高员工福利待遇，坚持广大干部员工对美
                    好生活的向往就是恒祥努力奋斗的目标，坚持让改革阳光照射到全体员工，实现发展成果由全体员工共享，与员工一起创造可持续的美好未来。
                </p>
            )
        }
        return (
            <p style={{textIndent: "2em", fontSize: 16, margin: "40px 0"}}>努力构建企业和员工协同发展的良好局面，关心关爱员工，想员工之所想，急员工之所急。坚定不移提高员工福利待遇，坚持广大干部员工对美
                好生活的向往就是恒祥努力奋斗的目标，坚持让改革阳光照射到全体员工，实现发展成果由全体员工共享，与员工一起创造可持续的美好未来。</p>
        )
    }, [tagId])
    return (
        <div>
            <Carousel imageList={imageList} />
            <div className={clsx(flex, flexCrossCenter, flexMainCenter)}>
                <div className={clsx(panelRoot)}>
                    <Tab list={list} tabId={tagId} setTabId={setTagId} />
                    <div style={{margin: "50px 0"}}>

                        {Content}
                    </div>
                    <div style={{margin: "50px 0"}}>
                        <ImageList list={imgList} />
                    </div>
                </div>
            </div>

        </div>
    )
};

export default PublicBenefit;
