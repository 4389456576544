import React, {ComponentType} from "react";
import {
    HashRouter as Router,
    Redirect,
    Route,
    Switch
} from "react-router-dom";


import Layout from "../pages/main";
import Home from "../pages/Home";
import GoHengXiang from "../pages/GoHengxing";
import IndustrialDistribution from "../pages/IndustrialDistribution";
import ChanYeBuJu from "../pages/IndustrialDistribution/indexV1";
import Dynamic from "../pages/Dynamic"
import AboutUs from "../pages/AboutUs";
import CarDetail from "../pages/IndustrialDistribution/CarDetail";
import PublicBenefit from "../pages/PublicBenefit";

import "moment/locale/zh-cn";

interface RouterInfo {
    path: string;
    component: ComponentType;
    redirect?: string;
    children?: RouterInfo[];
}
const routerList: RouterInfo[] = [
    {
        path: "/layout",
        component: Layout,
        redirect: "/home",
        children: [
            {
                path: "/home",
                component: Home
            },
            {
                path: "/toDetail/:id",
                component: GoHengXiang
            },
            {
                path: "/chanyebuju",
                component: ChanYeBuJu
            },
            {
                path: "/industrialDistribution/:id",
                component: IndustrialDistribution
            },
            {
                path: "/carDetail/:id",
                component: CarDetail
            },
            {
                path: "/dynamic",
                component: Dynamic
            },
            {
                path: "/contact",
                component: AboutUs
            },
            {
                path: "/publicBenefit/:id",
                component: PublicBenefit
            }
        ]
    }
];

const createBasicRoute = (route: RouterInfo, key: string) => { //  最基础的Router 用法
    const {path, component: Component} = route;
    return <Route exact key={`${key}-${path}`} path={path} component={() => <Component />} />;
};


const createFixRoute = (route: RouterInfo, key: string) => {
    const {path, component: RouteComponent, children, redirect} = route;
    if (children !== undefined) {
        return (
            <Route
                key={`${key}-${path}`}
                path={path}
                render={(props) => (
                    <RouteComponent {...props}>
                        <Switch>
                            {children.map((item) => {
                                const {path: childPath} = item;
                                return createFixRoute({...item, path: path + childPath}, path);
                            })}
                            {
                                redirect !== undefined ? <Redirect from={`${path}`} to={redirect} /> : null
                            }

                        </Switch>
                    </RouteComponent>
                )}
            />
        );
    }
    return createBasicRoute(route, key);
};

const createRoute = (routes: RouterInfo[]) => (
    <Switch>
        {
            routes.map((route) => createFixRoute(route, "/layout"))
        }
        {/* <Redirect from="/*" to="/login" /> */}
    </Switch>
);

const RouterComp = () => (
    <Router>
        <Redirect from="/*" to="/layout/home" />
        {createRoute(routerList)}
    </Router>
);


export default RouterComp;
