import React, {FC, useState, useMemo, useEffect} from "react";
import {useParams} from "react-router-dom";
import clsx from "clsx";
import useStyle from "./css";
import flexStyle from "../../common/style/flex";
import Tab from "../../components/Tab";
import Carousel from "../../components/Carousel";
import Car from "./Car";
import Trade from "./Trade";
import Electronic from "./Electronic";
// import Layout1 from "../../assets/baner.png";
import Layout2 from "../../assets/layout_2.png";
import Layout3 from "../../assets/tab_3.png";
import dianziPng from "../../assets/dianzi.png";
import {goPAGE} from "../../common/utils";
import CarVideo from "./Video";
import TitleBar from "../../components/TitleBar";
const list = [
    {
        name: "恒祥汽车",
        id: "1"
    },
    {
        name: "恒祥贸易",
        id: "2"
    },
    {
        name: "恒祥电子",
        id: "3"
    }
]

const IndustrialDistribution: FC = () => {
    const {id} = useParams() as {id: string};
    const {flex, flexCrossCenter, flexMainCenter, flexCrossStart} = flexStyle();
    const {root, pStyle, title, introduction} = useStyle(goPAGE());
    const [tabId, setTabId] = useState<string>("1");
    const [imageList] = useState<string[]>([Layout2, Layout3]);

    useEffect(() => {
        if (id !== undefined) {
            setTabId(id);
        }
    }, [id, setTabId])
    const TabContent = useMemo(() => {
        if (tabId === "1") {
            return (
                <div style={{margin: "80px 0 0 0"}}>
                    <div className={clsx(title)}>恒祥汽车</div>
                    <TitleBar />
                    <p className={clsx(pStyle)}>恒祥汽车零部件股份有限公司由恒祥控股集团有限公司投资，是一家主要业务聚焦于汽车零部件的制造，深耕于汽车转向系统和发动机系统
                        零部件的过程开发和生产，并在这两个系统零部件制造领域成功地向国外出口；目前客户多数为国内外知名企业，有捷豹路虎、蒂森克虏伯、捷太
                        格特、恩斯克、万都、长城及以奇瑞等。</p>
                    <Car />
                </div>
            )
        }
        if (tabId === "3") {
            return (
                <div style={{margin: "80px 0 0 0"}}>
                    <div className={clsx(title)}>恒祥贸易</div>
                    <TitleBar />
                    <p className={clsx(pStyle)}>恒祥贸易主要代理国内外名酒、饮料</p>
                    {/* <Trade /> */}
                </div>
            )
        }
        return (
            <div>
                <div className={clsx(flex, flexCrossStart, introduction)} style={{margin: "80px 0 0 0"}}>
                    <div>
                        <div className={clsx(title)}>恒祥电子</div>
                        <TitleBar />
                        <p className={clsx(pStyle)}>为了保证集团实业领域科技创新齐头并进，恒祥未来大力投资智能电子领域，要打造集研发和生产为一体的高科技术企业，制造属于自己的科技创新产品，服务于全世界。</p>
                        {
                            goPAGE()
                                ? (
                                    <div style={{width: "100%", margin: "0 0 0 20px"}}>
                                        <img src={dianziPng} alt="" />
                                    </div>
                                )
                                : null
                        }
                    </div>
                    {
                        goPAGE() === false
                            ? (
                                <div style={{width: "100%"}}>
                                    <img src={dianziPng} alt="" />
                                </div>
                            )
                            : null
                    }
                </div>
                <Electronic />
            </div>
        )
    }, [tabId, flex, flexCrossStart, introduction, title, pStyle]);

    const Header = useMemo(() => {
        if (tabId === "1") {
            return (
                <CarVideo />
            )
        }
        return (
            <Carousel imageList={imageList} />
        )
    }, [imageList, tabId])
    return (
        <div>
            {Header}
            <div className={clsx(flex, flexCrossCenter, flexMainCenter)}>
                <div className={clsx(root)}>
                    {/* <Tab list={list} tabId={tabId} setTabId={setTabId} /> */}

                    {TabContent}
                </div>
            </div>
        </div>

    )
};

export default IndustrialDistribution;
