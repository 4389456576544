import ZhiyuanzhePng from "../../assets/zhiyuan.png";
import ChenghaoPng from "../../assets/chenghao.png";
import ZhidaoPng from "../../assets/zhidao.png";
import XiangmuPng from "../../assets/xiangmu.png";
export const newListMock = [
    {
        time: "2022-04",
        title: "志愿服务助理防疫 振石先锋逆行而上",
        content: "志愿服务助理防疫 振石先锋逆行而上志愿服务助理防疫 振石先锋逆行而上志愿服务助理防疫 振石先锋逆行而上志愿服务助理防疫 振石先锋逆行而上志愿服务助理防疫 振石先锋逆行而上志愿服务助理防疫 振石先锋逆行而上志愿服务助理防疫 振石先锋逆行而上",
        image: ZhiyuanzhePng
    },
    {
        time: "2022-04",
        title: "宇视公司获评“2021年时代标杆企业”称号",
        content: "本报讯,近日，2021年浙江省物市办会工作年会举行，会议公布了 <2021时代标杆企业格单》，手石公司历平!2021时代东杆企业你号。过去一年，作为克兴市百家5A勿流企业，手石公司把坦机退，果焦创新，稳河远，拓水运，顾利通过4汽新业志新模武发属过点验收，成功人运中国民营物济企业50",
        image: ChenghaoPng
    },
    {
        time: "2022-04",
        content: "大振开4月s日，把三市一会形市长街天专一行花临宁石公司调研指导。桐三市统计厅， 发政后领导路司酒研，手石公司重手大在丹平瓶后糕待，会上，考丹平三香长又振远专品市是行的天方美示热烈欢巴_并对长班2来对丰石公司的关心与支特和示表心感山，在大气个经手石公司生产经有情况后，寿丹平集团年个项目人选桐二市重点科技计划项目",
        title: "桐乡市常务副市长穆运安一行莅临公司参观指导",
        image: ZhidaoPng
    },

    {
        time: "2022-04",
        title: "集团多个项目入选桐乡市重点科技计划项目",
        content: "本报讯 近口，桐生市科支后公开202,王康個三市五点科技计划项目，二能公司《市效玻璃纤维智能制造经制柔統的研发与产业化而目》华美公司《柔性地强管道用市强度HDPE基预浸带关键技大开左及而业仁)，恒石公司 《风电叶片主梁用快速导流织物E7-UD1450》入选，坛悉，市科技计划页目元营根",
        image: XiangmuPng
    }
]
