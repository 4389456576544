import React, {FC, useCallback, useEffect, useRef} from "react";
import clsx from "clsx";
import Video from "video.js";
import "video.js/dist/video-js.min.css";
import HomeVideo1 from "../../assets/video1.mp4";

const CarVideo: FC = () => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const player = useRef<any>();
    const setVideo = useCallback(() => {
        if (videoRef === null || videoRef.current === null) {
            setVideo();
        }
        player.current = Video(`#myvideo`, {
            controls: true,
            // 自动播放属性,muted:静音播放
            autoplay: "true",
            // 建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
            preload: "auto",
            // 设置视频播放器的显示宽度（以像素为单位）
            width: "100%",
            // 设置视频播放器的显示高度（以像素为单位）
            height: "calc(100vh - 80px)"
        });
        player.current.play();
        // player.current.on("error", (_event: any) => {
        //     player.current.reset();
        // });
    }, [])
    useEffect(() => {
        setVideo();
    }, [setVideo]);

    const onClick = useCallback(() => {
        player.current.play();
    }, [player]);

    useEffect(() => {
        return () => {
            player.current.dispose();
            player.current = null;
        }
    }, [])
    return (
        <div>
            <video id="myvideo" className={clsx(`video-js vjs-default-skin`)} ref={videoRef} style={{width: "100%", height: "calc(100vh - 80px)", objectFit: "fill"}}>
                {/* application/x-mpegURL */}
                <source src={HomeVideo1} />
            </video>
        </div>
    )
};

export default CarVideo;
