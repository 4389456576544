import {createUseStyles} from "react-jss";


const GoStyle = createUseStyles({
    root: {
        width: "100%"
    },
    banner: {
        height: "600px",
        width: "100%"
    },
    container: {
        width: "1200px",
    },
    tagContentTitle: {
        fontSize: "16px",
        textIndent: "2em"
    },
    title: {
        textAlign: "left",
        fontSize: "36px",
        fontWeight: "bold",
        margin: "0"
    },
    honorTab: {
        fontSize: "18px"
    },
    introduction: {
        "& img": {
            width: (props: boolean) => props ? "100%" : "100%",
            margin: (props: boolean) => props ? "20px 0 20px 0" : "0 0 0 20px"
        }
    },
    honorTabActive: {
        color: "#1890ff",
        borderBottom: "1px solid #1890ff"
    },
    sloganText: {
        fontSize: (props: boolean) => props ? "16px" : "24px",
        color: "rgba(2, 68, 140, 1)",
        textAlign: "center",
        fontWeight: "bold"
    },
    sloganSubText: {
        fontSize: (props: boolean) => props ? "14px" : "20px",
        color: "rgba(2, 68, 140, 1)",
        textAlign: "center",
        fontWeight: "bold"
    },
    sloganEnText: {
        fontSize: (props: boolean) => props ? "12px" : "18px",
        color: "rgba(2, 68, 140, 1)",
        textAlign: "center",
        fontWeight: "400",
        margin: "10px 0"
    }
});

export default GoStyle;
