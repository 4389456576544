import {createUseStyles} from "react-jss";

const imageListStyle = createUseStyles({
    root: {
        "& img": {
            width: "95%",
            margin: '0 auto'
        }
    },
    panelRoot: {
        width: "1200px",
        boxSizing: "border-box",
        padding: "20px 20px",
    }
});

export default imageListStyle;
