import {createUseStyles} from "react-jss";

const footerStyle = createUseStyles({
    root: {
        width: "100%",
        background: "#051E39",
        color: "white",
    },
    mobileRoot: {
        fontSize: "12px",
        lineHeight: "20px",
        width: "100%",
        background: "#051E39",
        color: "white",
        padding: "20px 10px",
        boxSizing: "border-box",
        margin: "20px 0 0 0",
        textAlign: "center"
    },
    container: {
        width: "1200px",
        padding: "20px 0",
        boxSizing: "border-box"
    },
    menuItem: {
        fontSize: "14px",
        lineHeight: "20px",
        margin: "0 10px 0 0",
        "&:hover": {
            color: "#1890ff"
        }
    },
    mobilemenuItem: {
        fontSize: "14px",
        lineHeight: "30px",
    },
    qrCodeImage: {
        height: "90px"
    }
});

export default footerStyle;
