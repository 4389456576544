import React, {FC, useCallback, useEffect, useRef, useState} from "react";
import clsx from "clsx";
import Video from "video.js";
import "video.js/dist/video-js.min.css";
import useStyle from "./css";
import flexStyle from "../../common/style/flex";
import Carousel from "../../components/Carousel";
import carouselPng from "../../assets/carousel_1.png";
import carousel2Png from "../../assets/carousel_2.png";
import carousel3Png from "../../assets/carousel_3.png";
import HomeVideo1 from "../../assets/video1.mp4";
import Qrcode from "../../assets/qrCode1.png";
// import carousel4Png from "../../assets/carousel_4.png";
import TabList from "./Tab";


const Home: FC = () => {
    const {flex, flexCrossCenter, flexMainCenter, pf} = flexStyle();
    const {root, controlBar, qrCodeStyle} = useStyle();
    const [carouselList] = useState<string[]>([carouselPng, carousel2Png, carousel3Png]);
    const player = useRef<any>();
    const videoRef = useRef<HTMLVideoElement | null>(null);

    const setVideo = useCallback(() => {
        if (videoRef === null || videoRef.current === null) {
            setVideo();
        }
        player.current = Video(`#myvideo`, {
            controls: true,
            // 自动播放属性,muted:静音播放
            autoplay: "true",
            // 建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
            preload: "auto",
            // 设置视频播放器的显示宽度（以像素为单位）
            width: "100%",
            // 设置视频播放器的显示高度（以像素为单位）
            height: "calc(100vh - 80px)"
        });
        player.current.play();
        // player.current.on("error", (_event: any) => {
        //     player.current.reset();
        // });
    }, [])
    useEffect(() => {
        setVideo();
    }, [setVideo]);

    const onClick = useCallback(() => {
        player.current.play();
    }, [player]);

    useEffect(() => {
        return () => {
            player.current.dispose();
            player.current = null;
        }
    }, [])

    return (
        <div>
            {/* <Carousel imageList={carouselList} /> */}
            <div className={clsx(flex, flexCrossCenter, flexMainCenter, controlBar)} onClick={onClick} style={{zIndex: "9999", }}>
                <video id="myvideo" className={clsx(`video-js vjs-default-skin`,)} ref={videoRef} style={{width: "100%", height: "calc(100vh - 80px)", objectFit: "fill"}}>
                    {/* application/x-mpegURL */}
                    <source src={HomeVideo1} />
                </video>
            </div>
            <div className={clsx(flex, flexCrossCenter, flexMainCenter)}>
                <div className={clsx(root)}>
                    <TabList showImage={false} />
                    {/* <News /> */}
                </div>
            </div>
            <div className={clsx(pf, qrCodeStyle)}>
                <img src={Qrcode} alt="" />
            </div>
        </div>
    )
};

export default Home;
