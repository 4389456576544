import React, {FC, useState, useCallback} from "react";
import clsx from "clsx";
import {useHistory} from "react-router-dom";
import flexStyle from "../../common/style/flex";
import useStyle from "./css";
import PlantPng1 from "../../assets/plant1.png";
import PlantPng2 from "../../assets/plant2.png";
import PlantPng3 from "../../assets/plant3.png";
import dalianPng from "../../assets/dalian.png";
import wuhuPng from "../../assets/wuhu.png";
import yuhuanPng from "../../assets/yuhuan.png";
import hezuo from "../../assets/hezuo.png";
import zhuyaoyewu from "../../assets/zhuyaoyewu.png"
const tabList = [
    {
        id: "1",
        name: "芜湖恒祥"
    },
    {
        id: "2",
        name: "大连恒祥"
    },
    {
        id: "3",
        name: "玉环恒祥"
    }
];
const getImage1 = (type: string) => {
    switch (type) {
        case "1":
            return wuhuPng
        case "2":
            return dalianPng
        case "3":
            return yuhuanPng
        default:
            return ""
    }
}
const getImage = (type: string) => {
    if (type === "1") {
        return PlantPng1
    }
    if (type === "2") {
        return PlantPng3
    }
    return PlantPng2
}
const getPContent = (type: string) => {
    switch (type) {
        case "1":
            return "芜湖恒祥实业有限公司是恒祥控股集团有限公司旗下的五家公司之一，公司注册于2011年，位于安徽省芜湖市经济技术开发区东梁路双闸支一路，拥有近1.8万平方米的标准厂房，现有员工120人，公司主要从事汽车铝合金压铸件铸造及铸造件、钢件精密加工。公司注重先进人才的引进，拥有来自日本爱信精机子公司及一汽光洋公司多人，分别来自技术、制造、质量三大主体部门，团队以丰田管理方式为主，管理理念先进，经验丰富；恒祥控股集团还会定期指派技术、质量总监来芜湖工厂指导、培训。"
        case "2":
            return `"大连恒祥汽车零部件制造有限公司坐落在国家一级开发区----大连开发区。
            公司于2010年开始筹建，2011年12月份建成投产，公司占地面积26000平方米，是以生产宝马、奔驰、大众系列汽车发动机端件的制造性企业，近年来，公司业务及规模不断壮大，现已拥有各种数控加工中心等大型设备200余台，拥有员工总数量270余人，为满足公司不断发展的需要，。
                  本公司长期以来，一直为蒂森克虏伯集团的全球工厂供应凸轮轴端件，合作项目包括大众、宝马、福特、奔驰、通用、一汽、东风、起亚等。
                由于本公司一贯坚持“质量第一，顾客至上”的原则，产品质量优良、价格合理、按期交货、服务周到、诚守信誉，多年来一直受到顾客的好评。从公司创建起，经过多年的艰苦创业和发展，现年产值近1亿元，公司于2017年获得蒂森克虏伯颁发的亚洲区最佳供应商的荣誉证书。
            公司拥有数控车床、加工中心、无心磨床、滚丝机、冲床、铣床、钻床、空压机、清洗机和烘干机等普通设备、高精度设备300多台，拥有齿轮测量中心、测量高精度的孔和轴的气动量仪、保证量具精度的校准设备、表面粗糙度检查仪、镀层测厚仪及平板测量的全套设备，检测金属材料硬度和金相组织的电动洛氏硬度计、维氏显微硬度计、金相显微镜，检测金属材料化学成份的全套理化室设备。
                庞大的设备体系奠定我们规模化的生产能力、完善的设备群组是我们供应市场的保证、先进齐全的检测手段是我们对质量要求的满足、时效权威的培训系统保证了我们的可持续发展，决策层超前的理念决定了全公司员工的行动。我们将不断追求以“质优、低价”的产品奉献给广大客户，我们将会是广大客户朋友忠诚的合作伙伴，让我们携手共创美好的明天。"`
        case "3":
            return "恒祥控股集团台州汽车零部件有限公司，创建于1996年。公司现有员工200名， 规划占地面积20000平方米，建筑面积11132平方米，年产值7000多万，位于浙江省玉环市沙门镇幸福塘路10号是玉环市汽摩配协会会员单位，企业连续获得 “玉环市巨龙企业””“市星级工业企业”、“一汽光洋优秀供应商”、“人才引进先进单位”等荣誉。恒祥公司生产的产品主要有锁紧螺母系列、压块塞系列、调整螺钉、锁环、挡块等转向系统配件。公司通过IATF16949-2016体系认证，主要客户有：一汽光洋、苏州万都、、捷太格特厦门&天津、长城蜂巢、豫北光洋、光洋连接器、协富光洋等国内外著名汽车零部件企业。"
        default:
            return ""
    }
}
const Car: FC = () => {
    const history = useHistory();
    const {flex, flexCrossCenter, flexMainAround, cursor, flexMainCenter} = flexStyle();
    const {honorTabActive, carBtn} = useStyle();
    const [type, setType,] = useState<string>("1");
    const goDetails = useCallback((type: string) => {
        history.push({
            pathname: `/layout/carDetail/${type}`
        })
    }, [history])
    return (
        <div>
            <img src={zhuyaoyewu} alt="" />
            <img src={hezuo} alt="" style={{width: "100%", margin: "-100px 0 50px 0"}} />
            <div className={clsx(flex, flexCrossCenter, flexMainAround)}>
                <div className={clsx(carBtn, flex, flexMainCenter, flexCrossCenter, cursor)} onClick={() => goDetails("yuhuan")}>玉环恒祥</div>
                <div className={clsx(carBtn, flex, flexMainCenter, flexCrossCenter, cursor)} onClick={() => goDetails("wuhu")}>芜湖恒祥</div>
                <div className={clsx(carBtn, flex, flexMainCenter, flexCrossCenter, cursor)} onClick={() => goDetails("dalian")}>大连恒祥</div>
            </div>
        </div>
    )
};

export default Car;
