import {createUseStyles} from "react-jss";

const homeStyle = createUseStyles({
    carouselStyle: {
        height: "450px",
        textAlign: "center",
    },
    root: {
        width: "100%",
        maxWidth: "1200px"
    },
    controlBar: {
        "& .video-js .vjs-big-play-button": {
            left: "50%",
            top: "50%"
        },
        "& .vjs-control-bar, .vjs-modal-dialog-content": {
            display: "none"
        }
    },
    qrCodeStyle: {
        right: "0",
        top: 90,
        padding: "20px",
        boxSizing: "border-box",
        background: "#ccc",
        borderRadius: "4px 0 0 4px",

        "& img": {
            width: "60px",
            height: "60px"
        }

    }
});

export default homeStyle;
