import {createUseStyles} from "react-jss";

const headerStyle = createUseStyles({
    root: {
        width: "100%",
        padding: "0 20px",
        boxSizing: "border-box",
        background: "white",
        "& > div": {
            height: "80px",
        },
        "& .ant-menu-horizontal": {
            border: "none"
        }
    },
    icon: {
        fontSize: "24px",
        color: "black",
        margin: "0 20px 0 0"
    },
    logo: {
        height: "40px"
    },
    drawerStyle: {
        "& .ant-drawer-content-wrapper": {
            width: "200px !important"
        }
    }
});

export default headerStyle;
