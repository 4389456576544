import {createUseStyles} from "react-jss";

const TabStyle = createUseStyles({
    header: {
        width: "100%",
        height: (props: boolean) => props ? "80px" : "200px",
        margin: "40px 0",
        background: "white"
    },
    tabItemStyle: {
        width: (props: boolean) => props ? "30%" : "260px",
        height: "100%",
        margin: "0 15px 0 0",
        fontSize: (props: boolean) => props ? "12px" : "24px",
        color: "rgb(23, 83, 148)",
        // "&:hover": {
        //     color: "white"
        // }
    },
    iconStyle: {
        width: (props: boolean) => props ? "40px" : "90px",
        height: (props: boolean) => props ? "40px" : "90px",
        margin: "0 auto 10px"
    },
    tabActive: {
        background: "#023c80",
        color: "white"
    }
});

export default TabStyle;
