import React, {FC, useState, useMemo} from "react";
import clsx from "clsx";
import useStyle from "./css";
import flexStyle from "../../common/style/flex";
import Tab from "../../components/Tab";
import Carousel from "../../components/Carousel";
import {dynamicCarousel} from "../../api";
import EnterpriseNews from "../../assets/enterprise_news.png";
import information from "../../assets/information.png";
import Leader from "./Leader";
import NewsItem, {NewsItemProps} from "./NewsItem";
import {newListMock} from "./mockData";

const list = [
    {
        name: "企业新闻",
        id: "1"
    },
    {
        name: "企业领袖",
        id: "2"
    },
    {
        name: "信息公示",
        id: "3"
    }
]

const Dynamic: FC = () => {
    const {flex, flexMainCenter, flexCrossCenter} = flexStyle();
    const {root, image} = useStyle();
    const [tagId, setTagId] = useState<string>("1");
    const [newlist, setNewList] = useState<NewsItemProps[]>(newListMock);

    const TabContent = useMemo(() => {
        if (tagId === "1") {
            return (
                newlist.map((el) => <NewsItem time={el.time} title={el.title} image={el.image} content={el.content} />)
            )
        }
        if (tagId === "2") {
            return <Leader />
        }
        return (
            newlist.map((el) => <NewsItem time={el.time} title={el.title} image={el.image} content={el.content} />)
        )
    }, [tagId, newlist])
    return (
        <div>
            <Carousel imageList={dynamicCarousel} />
            <div className={clsx(flex, flexMainCenter, flexCrossCenter)}>
                <div className={clsx(root)}>
                    <Tab list={list} tabId={tagId} setTabId={setTagId} />
                    {TabContent}
                </div>
            </div>
        </div>
    )
};

export default Dynamic;
