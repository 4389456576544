import React, {FC, useCallback, useEffect, useState} from "react";
import {Menu} from "antd";
import {MenuItem, menuList} from "../../api";
import {useHistory, useLocation} from "react-router-dom";
const {Item, SubMenu} = Menu;

interface MenuProps {
    mode: "horizontal" | "vertical" | "inline";
    clickItemCallback?: () => void;
}
const MenuList: FC<MenuProps> = (props) => {
    const {mode, clickItemCallback} = props;
    const history = useHistory();
    const {pathname} = useLocation();
    const [menuData] = useState<MenuItem[]>(menuList);
    const [currentMenu, setCurrentMenu] = useState<string>(pathname);

    useEffect(() => {
        setCurrentMenu(pathname);
    }, [setCurrentMenu, pathname])

    const handleClick = useCallback((value) => {
        if (value.key === "2" || value.key === "1") {
            return;
        }
        history.push(value.key);
        if (clickItemCallback !== undefined) {
            clickItemCallback();
        }
    }, [clickItemCallback, history])
    return (
        <Menu onClick={handleClick} selectedKeys={[currentMenu]} items={menuData} mode={mode} style={{fontSize: "20px", fontWeight: "bold"}}></Menu>
    )
}
export default MenuList;
