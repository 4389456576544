import React, {FC} from "react";
import clsx from "clsx";
import flexStyle from "../../common/style/flex";
const TitleBar: FC = () => {
    const {flex, flexCrossCenter} = flexStyle();
    return (
        <div className={clsx(flex, flexCrossCenter)} style={{height: "10px", margin: "0 0 30px 0"}}>
            <div style={{height: "100%", width: "60px", background: "rgb(23, 83, 148)", margin: "0 20px 0 0"}}></div>
            <div style={{width: "30px", height: "100%", background: "rgb(224, 71, 38)"}}></div>
        </div>
    )
};

export default TitleBar;
