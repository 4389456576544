import React, {FC, useState, useMemo} from "react";
import clsx from "clsx";
import {Space} from "antd";
import flexStyle from "../../common/style/flex";
import useStyle from "./css";
import Carousel from "../../components/Carousel";
import AboutUsPng from "../../assets/lianxiBanner.png";
import {goPAGE} from "../../common/utils";
import Lianxiwomen from "../../assets/lianxiwomen.png"
const AboutUs: FC = () => {
    const [carouselList] = useState<string[]>([AboutUsPng]);
    const [isMobile] = useState<boolean>(goPAGE());
    const {flex, flexCrossCenter, flexMainCenter, flexMainAround} = flexStyle();
    const {root, mobileItem, changtu, yuhuan, dalian, wuhu, shenzhen, xiaotu} = useStyle(isMobile);

    const Content = useMemo(() => {
        if (isMobile) {
            return (
                <div className={clsx(root)}>
                    <div className={clsx(mobileItem)}>销售联系方式：0571-56511362 </div>
                    <div className={clsx(mobileItem)}>芜湖联系方式：0553-2611450</div>
                    <div className={clsx(mobileItem)}>台州综合管理部-移动电话： 18757178591</div>
                    <div className={clsx(mobileItem)}>台州综合管理部-座机：0576-89900508</div>
                    <Space size={10}>
                        <div>人事：0411-39267182</div>
                        <div>采购：0411-39267153</div>

                    </Space>
                    <div className={clsx(mobileItem)}>销售：0411-39267155</div>
                </div>
            )
        }
        return (
            <>
                <div className={clsx(root)}>
                    <div>
                        <Space size={40}>
                            <div>销售联系方式：0571-56511362 </div>
                            <div>芜湖联系方式：0553-2611450</div>
                        </Space>
                    </div>
                    <div>
                        <Space size={40}>
                            <div>台州综合管理部-移动电话： 18757178591</div>
                            <div>台州综合管理部-座机：0576-89900508</div>
                        </Space>
                    </div>
                    <div>
                        <Space size={40}>
                            <div>人事：0411-39267182</div>
                            <div>采购：0411-39267153</div>
                            <div>销售：0411-39267155</div>
                        </Space>
                    </div>
                </div>
            </>
        )
    }, [isMobile])
    return (
        <div>
            <Carousel imageList={carouselList} />
            <div className={clsx(flex, flexCrossCenter, flexMainCenter)}>
                {/* {Content} */}
                <div className={clsx(root)}>
                    <div style={{fontSize: "36px", fontWeight: "bold", margin: "40px 0 0 0 ", textAlign: "center"}}>联系我们</div>
                    <div className={clsx(flex, flexMainCenter)}>
                        <div style={{width: "70px", height: "10px", background: "rgb(23, 83, 148)", margin: "0 0 40px 0"}}></div>
                    </div>
                    <div className={clsx(changtu)}></div>
                    <div className={clsx(flex, flexCrossCenter, flexMainAround)} style={{width: "100%", margin: "40px 0"}}>
                        <div className={clsx(yuhuan, xiaotu)}></div>
                        <div className={clsx(wuhu, xiaotu)}></div>
                        <div className={clsx(dalian, xiaotu)}></div>
                        <div className={clsx(shenzhen, xiaotu)}></div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AboutUs;
