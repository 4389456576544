import React, {FC, useCallback, useState} from "react";
import clsx from "clsx";
import flexStyle from "../../common/style/flex";
import LeftPng from "../../assets/left.png";
import RightPng from "../../assets/right.png";
import useStyle from "./css";
interface ImageListProps {
    list: {path: string, name: string}[][];
}
const ImageList: FC<ImageListProps> = (props) => {
    const {list} = props;
    const {root} = useStyle();
    const {flex, flexCrossCenter, flexItem, flexMainCenter, cursor, flexMainAround} = flexStyle();
    const [imgIndex, setImgIndex] = useState<number>(0);

    const next = useCallback(() => {
        if (imgIndex >= list.length - 1) {
            return;
        }
        setImgIndex(imgIndex + 1);
    }, [list, imgIndex, setImgIndex]);

    const topImg = useCallback(() => {
        if (imgIndex <= 0) {
            return;
        }
        setImgIndex(imgIndex - 1)
    }, [imgIndex, setImgIndex])
    return (
        <div className={clsx(flex, flexCrossCenter)} style={{}}>
            <div className={clsx(flex, flexMainCenter, flexCrossCenter)}>
                <img src={LeftPng} alt="" className={clsx(cursor)} onClick={topImg} height="60px" />
            </div>
            <div className={clsx(flexItem, root, flex, flexMainAround)}>
                {
                    list.length > 0 && list[imgIndex].length > 0 && list[imgIndex].map((el) => (
                        <div key={el.path} >
                            <div className={clsx(flex, flexMainCenter)}>
                                <img src={el.path} width="'95%" alt="" />
                            </div>
                            <div style={{fontSize: "16px", margin: "20px 0 0 0", textAlign: "center"}}>{el.name}</div>
                        </div>

                    ))
                }
            </div>
            <div className={clsx(flex, flexMainCenter, flexCrossCenter)}>
                <img src={RightPng} alt="" className={clsx(cursor)} onClick={next} height="60px" />
            </div>
        </div>
    )
};

export default ImageList;
