import React, {FC} from "react";
import {Carousel} from "antd";
import clsx from "clsx";
import useStyle from "./css";
import {uuid} from "../../common/utils";
interface CarouselImageProps {
    imageList: string[];
}
const CarouselImage: FC<CarouselImageProps> = (props) => {
    const {imageList} = props;
    const {carouselStyle} = useStyle();
    return (
        <Carousel autoplay>
            {
                imageList.map((el) => <img src={el} alt="" className={clsx(carouselStyle)} key={uuid()} />)
            }
        </Carousel>
    )
}
export default CarouselImage;
