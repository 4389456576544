import React, {FC, useState, useMemo} from "react";
import clsx from "clsx";
import flexStyle from "../../common/style/flex";
import useStyle from "./css";
import sloganPng from "../../assets/home_new_footer.png"
import LongButtom from "../../components/LongButton";
import List from "../../components/List";
import {activityList} from "../../api";
import {goPAGE} from "../../common/utils";
const tabList = [
    {
        id: "1",
        name: "企业Slogan"
    },
    {
        id: "2",
        name: "企业价值观"
    },
    {
        id: "3",
        name: "企业愿景"
    },
    {
        id: "4",
        name: "企业使命"
    }
]
// const getSloganText = (type: string) => {
//     switch (type) {
//         case "1":
//             return "恒于智造，铸就辉煌；凝聚你我，祥达八方"
//         case "2":
//             return "恒于智造，铸就辉煌；凝聚你我，祥达八方"
//         case "3":
//             return "以博学强智的力量，团结刻苦的品德， 秉承初心的热爱，铸就百年品牌企业"
//         case "4":
//             return "创造客户价值，实现员工梦想，助力社会和谐"
//         default:
//             return ""
//     }
// }
const Culture: FC = () => {
    const {flex, flexCrossCenter, flexMainAround, cursor} = flexStyle();
    const {honorTabActive, sloganText, sloganSubText, sloganEnText} = useStyle(goPAGE());
    const [type, setType] = useState<string>("1");
    const SloganText = useMemo(() => {
        if (type === "1") {
            return <div className={clsx(sloganText)}>恒于智造，铸就辉煌；凝聚你我，祥达八方</div>
        }
        if (type === "2") {
            return (
                // <div className={clsx(flex, flexMainCenter)}>
                <div className={clsx(flex, flexCrossCenter, flexMainAround)}>
                    <div>
                        <div className={clsx(sloganText)}>刻苦</div>
                        <div className={clsx(sloganEnText)}>Lucubration</div>
                        <div className={clsx(sloganSubText)}>勤奋刻苦， 打造非凡匠心！</div>
                    </div>
                    <div>
                        <div className={clsx(sloganText)}>博学</div>
                        <div className={clsx(sloganEnText)}>Knowledge</div>
                        <div className={clsx(sloganSubText)}>博学强智，打造非凡自我</div>
                    </div>
                    <div>
                        <div className={clsx(sloganText)}>热爱</div>
                        <div className={clsx(sloganEnText)}>Fervour</div>
                        <div className={clsx(sloganSubText)}>秉承初心，凝聚滴滴热爱</div>
                    </div>
                </div>
                // </div>
            )
        }
        if (type === "3") {
            return <div className={clsx(sloganText)}>以博学强智的力量，团结刻苦的品德， 秉承初心的热爱，铸就百年品牌企业</div>
        }
        return <div className={clsx(sloganText)}>创造客户价值，实现员工梦想，助力社会和谐</div>
    }, [type, flex, flexCrossCenter, flexMainAround, sloganText, sloganEnText, sloganSubText])
    return (
        <div>
            <div className={clsx(flex, flexCrossCenter, flexMainAround)} style={{margin: "50px 0 80px", fontSize: "18px"}}>
                {
                    tabList.map(({id, name}) => <div key={id} className={clsx(type === id ? honorTabActive : null, cursor)} onClick={() => setType(id)}>{name}</div>)
                }
            </div>
            {SloganText}
            <div>
                <img src={sloganPng} alt="" style={{width: "100%"}} />
            </div>

        </div>
    )
};

export default Culture;
