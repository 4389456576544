import React, {FC} from "react";
import clsx from "clsx";
import useStyle from "./css";
import flexStyle from "../../common/style/flex";
import Qrcode from "../../assets/qrCode1.png";
import Qrcode1 from "../../assets/qrCode2.png";

const Footer: FC = () => {
    const {mobileRoot, qrCodeImage} = useStyle();
    const {flex, flexCrossCenter, flexMainCenter} = flexStyle();
    return (
        <div className={clsx(mobileRoot)}>
            <div className={clsx()}>
                <div>
                    <div style={{margin: "0 0 20px 0"}}>
                        <div>Copyright 2021 恒祥控股集团有限公司</div>
                        <div>版权所有 浙ICP备09106888号</div>
                        <div>地址：浙江省杭州市萧山区平澜路259号绿都国金中心703</div>
                        <div>总机：0571-88888888 人事：0411-39267182</div>
                        <div>采购：0411-39267153 销售：0411-39267155</div>
                    </div>
                </div>
                <div className={clsx(flex, flexCrossCenter, flexMainCenter)}>
                    <img src={Qrcode} className={clsx(qrCodeImage)} style={{margin: "0 20px 0"}} alt="" />
                    <img src={Qrcode1} className={clsx(qrCodeImage)} alt="" />
                </div>
            </div>
        </div>
    )
};

export default Footer;
