import React, {FC, useMemo, useState} from "react";
import clsx from "clsx";
import useStyle from "./css";
import flexStyle from "../../common/style/flex";
import goBanner from "../../assets/go_banner.png";
import goBanner1 from "../../assets/go_banner1.png";
import goBanner2 from "../../assets/go_banner2.png";
import Tab from "../../components/Tab";
import Carousel from "../../components/Carousel";
import Intros from "./Intros";
import Honor from "./Honor";
import Culture from "./Culture";
import {useLocation, useParams, useRouteMatch} from "react-router-dom";

const list = [
    {
        name: "企业简介",
        id: "1"
    },
    {
        name: "企业荣誉",
        id: "2"
    },
    {
        name: "企业文化",
        id: "3"
    }
]

const GoHengXiang: FC = () => {
    const {id} = useParams() as {id: string}

    const {root, container} = useStyle();
    const {flex, flexCrossCenter, flexMainCenter} = flexStyle();
    const [imageList] = useState<string[]>([goBanner, goBanner1, goBanner2])



    const TagContent = useMemo(() => {
        if (id === "3") {
            return <Culture />
        }
        if (id === "2") {
            return <Honor />
        }
        return (
            <Intros />
        )
    }, [id]);
    return (
        <div className={clsx(root)}>
            <Carousel imageList={imageList} />
            <div className={clsx(flex, flexMainCenter, flexCrossCenter)} style={{margin: "20px 0 0 0"}}>
                <div className={clsx(container)}>
                    {/* <Tab list={list} tabId={tagId} setTabId={setTagId} /> */}
                    <div>
                        {TagContent}
                    </div>
                </div>
            </div>

        </div>
    )
};

export default GoHengXiang;
