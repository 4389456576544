import carPng from "../assets/icon5.png";
import activeCar from "../assets/icon6.png";
import tradePng from "../assets/icon1.png";
import activeTrade from "../assets/icon2.png";
import electronicPng from "../assets/icon3.png";
import activeElectronic from "../assets/icon4.png";
import DynamicBanner from "../assets/banner2.png";

import {MenuProps} from "antd";
export type MenuItem = Required<MenuProps>['items'][number];
export const dynamicCarousel = [DynamicBanner];
// export interface MenuItem {
//     id: string;
//     name: string;
//     path: string;
//     children?: MenuItem[];
// }
export const menuList: MenuItem[] = [
    {
        // key: "/layout/home",
        label: "首页",
        key: "/layout/home"
    },
    {
        // id: "/layout/toDetail",
        label: "走进恒祥",
        key: "/layout/toDetail",
        children: [
            {
                label: '企业简介',
                // id: 'setting:1',
                key: "/layout/toDetail/1",
            },
            {
                label: '企业荣誉',
                // id: 'setting:2',
                key: "/layout/toDetail/2",
            },
            {
                label: '企业文化',
                // id: 'setting:2',
                key: "/layout/toDetail/3",
            },
        ],
    },
    {
        // id: "/layout/industrialDistribution",
        label: "产业布局",
        key: "/layout/chanyebuju",
        // children: [
        //     {
        //         label: '恒祥汽车',
        //         // id: 'setting:1',
        //         key: "/layout/industrialDistribution/1",
        //     },
        //     {
        //         label: '恒祥电子',
        //         // id: 'setting:2',
        //         key: "/layout/industrialDistribution/2",
        //     },
        //     {
        //         label: '恒祥贸易',
        //         // id: 'setting:2',
        //         key: "/layout/industrialDistribution/3",
        //     },
        // ],
    },
    {
        // id: "/layout/dynamic",
        label: "企业动态",
        key: "/layout/dynamic"
    },
    {
        // id: "serviceAgreement",
        label: "恒祥公益",
        key: "/layout/publicBenefit",
        children: [
            {
                label: "员工关怀",
                key: "/layout/publicBenefit/1"
            },
            {
                label: "社会责任",
                key: "/layout/publicBenefit/2"
            },
        ]
    },
    {
        // id: "/layout/contact",
        label: "联系我们",
        key: "/layout/contact"
    },
]

export const StepList = [
    {
        title: "1995",
        description: "玉环恒祥成立"
    },
    {
        title: "2001",
        description: "更名浙江恒祥"
    },
    {
        title: "2009",
        description: "台州福元运通"
    },
    {
        title: "2011",
        description: "大连恒祥"
    },
    {
        title: "2013",
        description: "恒祥控股"
    },
    {
        title: "2014",
        description: "芜湖恒祥浙江万泰"
    },
]

export interface TabItem {
    id: string;
    name: string;
    icon: string;
    activeIcon: string;
}
export const tabList: TabItem[] = [
    {
        id: "1",
        name: "恒祥汽车",
        icon: carPng,
        activeIcon: activeCar
    },
    {
        id: "3",
        name: "恒祥贸易",
        icon: tradePng,
        activeIcon: activeTrade
    },
    {
        id: "2",
        name: "恒祥电子",
        icon: electronicPng,
        activeIcon: activeElectronic
    }
]

export const HonorText = [
    `集团在管理模式上不断创新改革，持续推进精益生产，强化质量管理，并先后引进了ERP、PLM、MES、OA等先进管理软件，
    实现生产和办公等自动化，有力地提升了管理与生产技术力量和产品设计研发能力，同时积累丰富的制造经验，不断为客户在结构
    优化、性能改善、成本控制等方面创造更多价值。`,
    `恒祥控股集团始终坚持“关注你所专注，放心你所用心”的顾客满意方针；围绕“追求安全、环保和品质无缺”的产品目标；
    建立各项制度实行规范化管理，塑造企业核心竞争力。集团下属所有子公司都通过了IATF16949、ISO45001和ISO14000管理体系
    认证。`
]
export const activityList = [
    {
        imagePath: "",
        title: "大连员工篮球赛",
        description: "大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛"
    },
    {
        imagePath: "",
        title: "大连员工篮球赛",
        description: "大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛"
    },
    {
        imagePath: "",
        title: "大连员工篮球赛",
        description: "大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛大连员工篮球赛"
    }
]
