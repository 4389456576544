import React, {FC, useCallback, useState} from "react";
import clsx from "clsx";
import {Drawer} from "antd";
import {UnorderedListOutlined, MenuFoldOutlined} from "@ant-design/icons"
import useStyle from "./css";
import flexStyle from "../../common/style/flex";
import LogoPng from "../../assets/logo.png";
import Menu from "../Menu";
import {goPAGE} from "../../common/utils";


const Header: FC = () => {
    const {root, icon, logo, drawerStyle} = useStyle();
    const {flex, flexCrossCenter, flexMainJustify} = flexStyle();
    const [showDrawer, setShowDrawer] = useState<boolean>(false);
    const [isMobile] = useState<boolean>(goPAGE());
    const clickIcon = useCallback(() => {
        setShowDrawer(!showDrawer);
    }, [setShowDrawer, showDrawer])
    return (
        <div className={clsx(root)}>
            <div className={clsx(flex, flexCrossCenter, flexMainJustify)}>
                <div className={clsx(flex, flexCrossCenter)}>
                    {
                        isMobile
                            ? <UnorderedListOutlined className={clsx(icon)} onClick={clickIcon} />
                            : null
                    }
                    <img className={clsx(logo)} src={LogoPng} alt="" />
                </div>
                {
                    isMobile === false
                        ? <Menu mode="horizontal" />
                        : null
                }
                <Drawer placement="left" visible={showDrawer} closable={false} className={clsx(drawerStyle)}
                    title={(<MenuFoldOutlined className={clsx(icon)} onClick={clickIcon} />)}
                    maskClosable={false}>
                    <Menu mode="vertical" clickItemCallback={clickIcon} />
                </Drawer>
            </div>
        </div>
    )
};

export default Header;
