import React, {FC, useCallback} from "react";
import clsx from "clsx";
import {useHistory} from "react-router-dom";
import flexStyle from "../../common/style/flex";
import useStyle from "./cssV1";


const ChanYeBuJu: FC = () => {
    const history = useHistory();
    const {root1, dianziStyle, maoyiStyle} = useStyle();
    const {flex, flexCrossCenter, flexItem, cursor} = flexStyle();
    const toView = useCallback((type: string) => {
        history.push(`/layout/industrialDistribution/${type}`)
    }, [])
    return (
        <div className={clsx(flex, flexCrossCenter)} style={{width: "100%", height: "calc(100vh - 80px)"}}>
            <div className={clsx(flexItem, root1, cursor)} onClick={() => toView("1")}></div>
            <div className={clsx(flexItem, dianziStyle, cursor)} onClick={() => toView("2")}></div>
            <div className={clsx(flexItem, maoyiStyle, cursor)} onClick={() => toView("3")}></div>
            {/* <img src={Qiche} alt="" height="100%" width="400px" className={clsx(flexItem)} onClick={() => toView("1")} />
            <img src={Dianzi} alt="" height="100%" width="400px" className={clsx(flexItem)} onClick={() => toView("2")} />
            <img src={Maoyi} alt="" height="100%" width="400px" className={clsx(flexItem)} onClick={() => toView("3")} /> */}
        </div>
    )
};

export default ChanYeBuJu;
