import React, {FC, useState, useCallback} from "react";
import clsx from "clsx";
import useStyle from "./css";
import {useHistory} from "react-router-dom";
import flexStyle from "../../../common/style/flex";
import {getImage} from "./data";
import {tabList, TabItem, } from "../../../api";
import {goPAGE} from "../../../common/utils";


interface TabContentProps {
    showImage?: boolean;
}
const TabContent: FC<TabContentProps> = (props) => {
    const {showImage = true} = props;
    const history = useHistory();
    const {header, tabItemStyle, tabActive, iconStyle} = useStyle(goPAGE());
    const {flex, flexCrossCenter, flexMainCenter} = flexStyle();
    const [list] = useState<TabItem[]>(tabList);
    const [idList, setIdList] = useState<string>("");
    const [imageId, setImageId] = useState<string>("")
    const clickItem = useCallback((id) => {
        setIdList(id);
        history.push({
            pathname: `industrialDistribution/${id}`,
        })
    }, [setIdList, history]);
    const onMouseOver = useCallback((id) => {
        setImageId(id);
    }, [setImageId]);

    const onMouseOut = useCallback((id) => {
        setImageId("");
    }, [setImageId]);
    return (
        <div>
            <div className={clsx(flex, flexCrossCenter, flexMainCenter, header)}>
                {
                    list.map(({id, name, icon, activeIcon}) => (
                        <div
                            onClick={() => clickItem(id)}
                            onMouseOver={() => onMouseOver(id)}
                            onMouseOut={() => onMouseOut(id)}
                            className={clsx(tabItemStyle, flex, flexCrossCenter, flexMainCenter, idList === id ? tabActive : null)}
                            key={id}>
                            <div>
                                <div className={clsx(flex, flexCrossCenter, flexMainCenter)}>
                                    <img className={clsx(iconStyle)} src={idList === id || imageId === id ? activeIcon : icon} alt="" />
                                </div>
                                <div>{name}</div>
                            </div>
                        </div>
                    ))
                }
            </div>
            {
                showImage
                    ? <div>
                        <img src={getImage(imageId === "" ? idList : imageId)} alt="" style={{width: "100%"}} />
                    </div>
                    : null
            }

        </div>
    )
};

export default TabContent;
