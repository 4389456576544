import {createUseStyles} from "react-jss";

const DynamicStyle = createUseStyles({
    root: {
        width: "100%",
        maxWidth: "1200px"
    },
    image: {
        width: "100%"
    },
    LeaderBtn: {
        padding: (props: boolean) => props ? "10px" : "30px 30px 0",
        boxSizing: "border-box",
        // background: "linear-gradient(90deg, #0c4790 0%, #d8401a 100%)",
        fontSize: "20px",
        color: "rgb(23, 83, 148)",
        margin: "0 0 20px 0"
    },
    LeaderContent: {
        textIndent: "2em",
        fontSize: "16px"
    },
    timeStyle: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "rgb(130, 126, 131)",
        margin: "0 20px 25px 0",
        width: "150px",
    },
    newsRoot: {
        padding: "10px",
        boxSizing: "border-box",
        border: '1px solid #ccc',
        margin: "0 0 20px 0"
    },
    newTitle: {
        fontSize: "22px",

    },
    newTitleColor: {
        color: "#000"
    },
    newTitleColorHover: {
        color: "rgb(150, 47, 55)"
    },
    imageStyle: {
        margin: "0 0 0 20px",
        "& img": {
            height: "200px"
        }
    },
    iconStyle: {
        width: "60px",
        height: "40px",
        fontSize: "18px",
        color: "rgb(157, 159, 163)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
            background: "rgb(150, 47, 55)",
            color: "white"
        }
    }
});

export default DynamicStyle;
