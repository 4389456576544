import React, {FC, useState} from "react";
import clsx from "clsx";
import {ArrowRightOutlined} from '@ant-design/icons';
import flexStyle from "../../common/style/flex";
import useStyle from "./css";
export interface NewsItemProps {
    time: string;
    title: string;
    content: string;
    image: string;
}
const NewsItem: FC<NewsItemProps> = (props) => {
    const {time, title, content, image} = props;
    const {flex, flexCrossCenter} = flexStyle();
    const {timeStyle, newTitle, newTitleColor, imageStyle, iconStyle, newTitleColorHover, newsRoot} = useStyle();
    const [isHover, setIsOver] = useState<boolean>(false);
    return (
        <div className={clsx(flex, flexCrossCenter, newsRoot)}>
            <div>
                <div className={clsx(timeStyle)}>{time}</div>
                <div>
                    <ArrowRightOutlined className={clsx(iconStyle)} onMouseEnter={() => setIsOver(true)} onMouseLeave={() => setIsOver(false)} />
                </div>
            </div>
            <div>
                <div className={clsx(newTitle, isHover ? newTitleColorHover : newTitleColor)}>{title}</div>
                <div>{content}</div>
            </div>
            <div className={clsx(imageStyle)}>
                <img src={image} alt="" />
            </div>
        </div>
    )
};

export default NewsItem;
