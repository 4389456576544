import React, {FC, useState} from "react";
import clsx from "clsx";
import {useParams} from "react-router-dom";
import Carousel from "../../components/Carousel";
import Layout1 from "../../assets/layout_1.png";
import ElectronicTabPng from "../../assets/electronic_tab.png";
import flexStyle from "../../common/style/flex";
import useStyle from "./css";
import changquShow from "../../assets/changquShow.png";
import changquShowWuhu from "../../assets/changquShowWuhu.png";
import changquShowDalian from "../../assets/changquShowDalian.png";
import yunli from "../../assets/changqu.png";
import TitleBar from "../../components/TitleBar";
import DaLianChanPin from "../../assets/dalianchanpin.png";
import TaiZhouChanPin from "../../assets/taizhouchanpin.png";

const getImage = (type: string) => {
    switch (type) {
        case "yuhuan":
            return changquShow;
        case "wuhu":
            return changquShowWuhu;
        case "dalian":
            return changquShowDalian;
        default:
            return "";
    }
}

const getImageV1 = (type: string) => {
    switch (type) {
        case "yuhuan":
            return TaiZhouChanPin;
        case "wuhu":
            return yunli;
        case "dalian":
            return DaLianChanPin;
        default:
            return "";
    }
}

const getSubTitle = (type: string) => {
    switch (type) {
        case "yuhuan":
            return "玉环";
        case "wuhu":
            return "安徽芜湖"
        default:
            return "大连"
    }
}

const getPtitle = (type: string) => {
    switch (type) {
        case "yuhuan":
            return "玉环";
        case "wuhu":
            return "芜湖"
        default:
            return "大连"
    }
}
const CarDetail: FC = () => {
    const {id} = useParams() as {id: string};
    const {root, title, pStyle} = useStyle();
    const {flex, flexMainCenter, flexCrossCenter} = flexStyle();
    const [imageList] = useState<string[]>([Layout1]);


    return (
        <div>
            <Carousel imageList={imageList} />
            <div className={clsx(flex, flexMainCenter, flexCrossCenter)}>
                <div className={clsx(root)}>
                    <div className={clsx(title)} style={{margin: "80px 0 0 0"}}>恒祥汽车-<span style={{fontSize: "28px"}}>{`${getSubTitle(id)}厂区`}</span></div>
                    <TitleBar />
                    <p className={clsx(pStyle)}>
                        {
                            `${getPtitle(id)}恒祥实业有限公司是恒祥控股集团有限公司旗下的五家公司之一，公司注册于2011年，位于安徽省芜湖市经济技术开发区东梁路双闸支一路，拥有近1.8万
                            平方米的标准厂房，现有员工120人，公司主要从事汽车铝合金压铸件铸造及铸造件、钢件精密加工。公司注重先进人才的引进，拥有来自日本爱信精机子公司及一
                            汽光洋公司多人，分别来自技术、制造、质量三大主体部门，团队以丰田管理方式为主，管理理念先进，经验丰富；恒祥控股集团还会定期指派技术、质量总监来芜
                            湖工厂指导、培训。`
                        }
                    </p>
                    <img src={getImage(id)} alt="" style={{width: "100%"}} />
                    <img src={getImageV1(id)} alt="" style={{width: "100%", margin: "20px 0 0 0"}} />
                </div>
            </div>
        </div>
    )
};

export default CarDetail;
