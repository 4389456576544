import {createUseStyles} from "react-jss";
import dizhi1 from "../../assets/dizhi1.png";
import yuhuandizhi from "../../assets/yuhuandizhi.png";
import whdz from "../../assets/wuhudizhi.png";
import dldz from "../../assets/daliandizhi.png";
import szdz from "../../assets/shenzhendizhi.png";

const aboutStyle = createUseStyles({
    carouselStyle: {
        height: "450px",
        textAlign: "center",
    },
    root: {
        width: "100%",
        maxWidth: "1200px",
        fontSize: (props: boolean) => props ? "12px" : "24px",
        padding: (props: boolean) => props ? "20px" : "30px 30px 0",
        boxSizing: "border-box"
    },
    mobileItem: {
        margin: "10px 0"
    },
    changtu: {
        width: "1140px",
        height: "260px",
        background: `url(${dizhi1})`,
        backgroundSize: "contain",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        transition: "width 0.2s, height 0.2s",
        "&:hover": {
            width: "1200px",
            height: "280px",
        }
    },
    xiaotu: {
        width: "265px",
        height: "265px",

        transition: "width 0.2s, height 0.2s",
        "&:hover": {
            width: "325px",
            height: "325px",
        }
    },
    yuhuan: {
        background: `url(${yuhuandizhi})`,
        backgroundSize: "100% 100%",
    },
    wuhu: {
        background: `url(${whdz})`,
        backgroundSize: "100% 100%",
    },
    dalian: {
        background: `url(${dldz})`,
        backgroundSize: "100% 100%",
    },
    shenzhen: {
        background: `url(${szdz})`,
        backgroundSize: "100% 100%",
    }
});

export default aboutStyle;
