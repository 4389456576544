import React, {FC, useCallback} from "react";
import clsx from "clsx";
import useStyle from "./css";
import flexStyle from "../../common/style/flex";
import Qrcode from "../../assets/qrCode1.png";
import Qrcode1 from "../../assets/qrCode2.png";
import {menuList} from "../../api";
import {useHistory} from "react-router-dom";

const Footer: FC = () => {
    const history = useHistory();
    const {root, container, menuItem, qrCodeImage} = useStyle();
    const {flex, flexCrossCenter, flexMainCenter, flexMainJustify, cursor} = flexStyle();
    const clickFooter = useCallback((menuPath: string) => {
        if (menuPath !== "") {
            history.push(menuPath);
        }
    }, [history])
    return (
        <div className={clsx(root, flex, flexCrossCenter, flexMainCenter)}>
            <div className={clsx(container, flex, flexCrossCenter, flexMainJustify)}>
                <div>
                    <div className={clsx(flex, flexCrossCenter)} style={{margin: "0 0 20px 0"}}>
                        {
                            menuList.map((el) => <div key={el?.key} className={clsx(menuItem, cursor)}>{(el as any).label}</div>)
                        }
                    </div>
                    <div>
                        <div>Copyright 2021 恒祥控股集团有限公司 版权所有 浙ICP备09106888号</div>
                        <div>地址：浙江省杭州市萧山区平澜路259号绿都国金中心703 &nbsp;&nbsp;总机：0571-88888888 </div>
                        {/* <div>总机：0571-88888888 人事：0411-39267182 采购：0411-39267153 销售：0411-39267155</div> */}
                    </div>
                </div>
                <div className={clsx(flex, flexCrossCenter)}>
                    <img src={Qrcode} className={clsx(qrCodeImage)} style={{margin: "0 20px 0"}} alt="" />
                    <img src={Qrcode1} className={clsx(qrCodeImage)} alt="" />
                </div>
            </div>
        </div>
    )
};

export default Footer;
