import React, {FC, useState} from "react";
import Header from "../../components/Header";
import clsx from "clsx";
import flexStyle from "../../common/style/flex";
import Footer from "../../components/Footer";
import MobileFooter from "../../components/Footer/mobileFooter";
import {goPAGE} from "../../common/utils";

const Main: FC = (props) => {
    const {children} = props;
    const [isMobile] = useState<boolean>(goPAGE());
    const {flex, flexTop, flexItem} = flexStyle();
    return (
        <div className={clsx(flex, flexTop)} style={{background: "white"}}>
            <Header />
            <div className={clsx(flexItem)} style={{background: "white"}}>
                {children}
            </div>
            {
                isMobile
                    ? <MobileFooter />
                    : <Footer />
            }
        </div>
    )
};

export default Main;
