
import TabOnePng from "../../../assets/tab_1.png";
import TabTwoPng from "../../../assets/tab_2.png";
import TabThreePng from "../../../assets/tab_3.png";

export const getImage = (id: string) => {
    if (id === "1") {
        return TabOnePng;
    }
    if (id === "2") {
        return TabTwoPng;
    }
    return TabThreePng;
}
