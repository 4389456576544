import {createUseStyles} from "react-jss";

const flexStyle = createUseStyles({
    flex: {
        display: "flex"
    },
    flexTop: {
        flexDirection: "column"
    },
    flexCrossCenter: {
        alignItems: "center"
    },
    flexCrossBaseline: {
        alignItems: "baseline"
    },
    flexCrossStart: {
        alignItems: "flex-start"
    },
    flexMainCenter: {
        justifyContent: "center"
    },
    flexMainJustify: {
        justifyContent: "space-between"
    },
    flexMainAround: {
        justifyContent: "space-around"
    },
    flexMainEnd: {
        justifyContent: "flex-end"
    },
    flexMainStart: {
        justifyContent: "flex-start"
    },
    flexWrap: {
        flexWrap: "wrap"
    },
    flexCrossStretch: {
        alignTtems: "stretch"
    },
    flexItem: {
        flex: "1"
    },
    cursor: {
        cursor: "pointer"
    },
    pr: {
        position: "relative"
    },
    pa: {
        position: "absolute"
    },
    pf: {
        position: "fixed"
    },
    textOverFlow: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
});

export default flexStyle;
