import React, {FC, useState} from "react";
import clsx from "clsx";
import {HonorText} from "../../api";
import {uuid} from "../../common/utils";
import flexStyle from "../../common/style/flex";
import useStyle from "./css";

import nationPng from "../../assets/nation.png";
import cityPng from "../../assets/city.png";
import TitleBar from "../../components/TitleBar";

const Honor: FC = () => {
    const {flex, flexCrossCenter, flexMainCenter, cursor} = flexStyle();
    const {honorTab, honorTabActive, title} = useStyle();
    const [type, setType] = useState<string>("1"); // 1国家级 2:省市级

    return (
        <div>
            <div className={clsx(title)} style={{margin: "80px 0 0 0"}}>集团荣誉</div>
            <TitleBar />
            <div style={{fontSize: "16px", margin: "50px 0"}}>
                {
                    HonorText.map((el) => <p key={uuid()} style={{textIndent: "2em"}}>{el}</p>)
                }
            </div>
            <div className={clsx(flex, flexCrossCenter, flexMainCenter, honorTab)} style={{margin: ""}}>
                <div style={{margin: "0 20px 0 0"}} className={clsx(type === "1" ? honorTabActive : null, cursor)} onClick={() => setType("1")}>国家级</div>
                <div className={clsx(type === "2" ? honorTabActive : null, cursor)} onClick={() => setType("2")}>省市级</div>
            </div>
            <img src={type === "1" ? nationPng : cityPng} style={{width: "100%", margin: "30px 0 0 0"}} />
        </div>
    )
};

export default Honor;
