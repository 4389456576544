import React, {FC, useState, useMemo} from "react";
import clsx from "clsx";
import flexStyle from "../../common/style/flex";
import useStyle from "./css";
import LingxiuPng from "../../assets/linxiu.png"
import honorShow from "../../assets/honorShow.png"
import {goPAGE} from "../../common/utils";
const Leader: FC = () => {
    const [isMobile] = useState<boolean>(goPAGE());
    const {flex, flexCrossCenter, flexWrap} = flexStyle();
    const {LeaderBtn, LeaderContent} = useStyle(isMobile);


    const Content = useMemo(() => {
        if (isMobile) {
            return (
                <div className={clsx()}>
                    <img src={LingxiuPng} alt="" style={{width: "100%", margin: "0 0 30px 0"}} />
                    <div>
                        <div className={clsx(LeaderBtn)}>
                            <div>李祥木</div>
                            <div>集团董事长&创始人</div>
                        </div>
                        <p className={clsx(LeaderContent)}>
                            李祥木先生  恒祥控股集团董事长  浙江优秀民营企业家 现任玉环汽摩配协会副会长、大连市台州商会副会长和万都商会副会长。
                            至1995年创办企业以来，秉承诚信经营理念，坚持以“创造客户价值、实现员工梦想和助力社会和谐”为使命，始终给企业灌输科技创新和科学管理的先进理念，使企业先后荣获当地“先进单位”、“玉龙企业”、“汽摩配理事单位”以及多个客户优秀合作商等荣誉。
                            创业以来一直持续打造恒祥品牌，现已经把恒祥集团打造成为投资、汽车零部件、智能电子和商业贸易综合四大领域集团航母；
                            李祥木董事长在事业发展之余，从不忘回馈社会和关心社会群体，热心公益和关心员工，每年多次捐助困难学子、受灾人群和困难员工，得到了各界人士的认可和好评。
                        </p>
                    </div>
                </div>
            )
        }
        return (
            <div className={clsx(flex, flexCrossCenter)} style={{margin: "100px 0 50px"}}>
                <img src={LingxiuPng} alt="" style={{height: "370px", margin: "0 30px 0 0"}} />
                <div>
                    <div className={clsx(LeaderBtn)}>
                        <div style={{fontSize: "36px", fontWeight: "bold"}}>李祥木</div>
                        <ul className={clsx(flex, flexWrap)}>
                            <li style={{margin: "0 20px 0 0"}}>恒祥控股集团董事长</li>
                            <li>大连市台州商会副会长</li>
                            <li style={{margin: "0 20px 0 0"}}>浙江优秀民营企业家</li>
                            <li>万都商会副会长</li>
                            <li>玉环汽摩配协会副会长</li>

                        </ul>
                    </div>
                    <p className={clsx(LeaderContent)}>
                        李祥木先生  恒祥控股集团董事长  浙江优秀民营企业家 现任玉环汽摩配协会副会长、大连市台州商会副会长和万都商会副会长。
                        至1995年创办企业以来，秉承诚信经营理念，坚持以“创造客户价值、实现员工梦想和助力社会和谐”为使命，始终给企业灌输科技创新和科学管理的先进理念，使企业先后荣获当地“先进单位”、“玉龙企业”、“汽摩配理事单位”以及多个客户优秀合作商等荣誉。
                        创业以来一直持续打造恒祥品牌，现已经把恒祥集团打造成为投资、汽车零部件、智能电子和商业贸易综合四大领域集团航母；
                        李祥木董事长在事业发展之余，从不忘回馈社会和关心社会群体，热心公益和关心员工，每年多次捐助困难学子、受灾人群和困难员工，得到了各界人士的认可和好评。
                    </p>
                </div>
            </div>
        )
    }, [isMobile, LeaderBtn, LeaderContent, flex, flexCrossCenter])
    return (
        <div>
            <div style={{width: "80%", margin: "0 auto"}}>
                {/* <div className={clsx(flex, flexCrossCenter)}>
                    <img src={LingxiuPng} alt="" style={{height: "370px", margin: "0 30px 0 0"}} />
                    <div>
                        <div className={clsx(LeaderBtn)}>
                            <div>李祥木</div>
                            <div>集团董事长&创始人</div>
                        </div>
                        <p className={clsx(LeaderContent)}>创建于1995年，是一家优秀的民营企业集团。业务范围涉及汽车零部件、环境工程、旅游发展、资本运作等领域。
                            目前主要业务聚焦于汽车零部件的制造，深耕于汽车转向系统和发动机系统零部件的过程开发和生产，并在这两个系统零部件制造领域成功地向国外出口</p>
                    </div>
                </div> */}
                {
                    Content
                }
                <img src={honorShow} alt="" style={{width: "100%", margin: "30px 0"}} />
            </div>

        </div>
    )
};

export default Leader
