import {createUseStyles} from "react-jss";

const CarouselStyle = createUseStyles({
    carouselStyle: {
        // height: (props: boolean) => props ? "400px" : "600px",
        width: "100%",
        maxHeight: "600px",
        textAlign: "center",
    }
});

export default CarouselStyle;
