import {createUseStyles} from "react-jss";

const TabStyle = createUseStyles({
    tagList: {
        width: "100%",
        margin: "20px 0"
    },
    tag: {
        height: "50px",
        fontSize: "18px",
        border: "1px solid #efefef",
        boxSizing: "border-box",
        "&:hover": {
            background: "linear-gradient(90deg, #0c4790 0%, #d8401a 100%)",
            color: "white"
        }
    },
    tagActive: {
        background: "linear-gradient(90deg, #0c4790 0%, #d8401a 100%)",
        color: "white"
    },
});

export default TabStyle;
